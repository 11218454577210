import {BaseParser} from '@form-create/core';

const name = 'hidden';

class parser extends BaseParser {
    render() {
        return [];
    }
}

export default {parser, name};
